import { useSearchParams } from "@solidjs/router";
import { createSignal, onMount } from "solid-js";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import { showSnackBar } from "~/shared_states/snackbar";
import AppWebView from "~/utils/app_web_view/trigger_app_events";
import { ButtonRegular } from "~/widgets/button";
import { Spacer } from "~/widgets/spacer";

export default function ExampleWebPage() {
  const [queryParams] = useSearchParams();
  console.log("queryParams", queryParams.sid);
  const [userData, setUserData] = createSignal<Record<string, any>>({});

  onMount(() => {
    // @ts-ignore
    window.HubbleAppWebView.onUserInfo = (message: any) => {
      try {
        setUserData(JSON.parse(message));
        showSnackBar({
          message: `User data received: ${userData().phone}`,
          level: "info",
        });
      } catch (e: any) {
        showSnackBar({
          message: e.message,
          level: "error",
        });
      }
    };

    // @ts-ignore
    window.HubbleAppWebView.onUserStateUpdate = () => {
      showSnackBar({
        message: "User state updated",
        level: "info",
      });
    };

    // @ts-ignore
    window.HubbleAppWebView.onLocationFetch = (message: string) => {
      showSnackBar({
        message: `L:${message}`,
        level: "info",
      });
    };
  });

  return (
    <div class="flex w-full flex-col items-center justify-start  bg-baseTertiaryLight px-4">
      <ClientOnlyComponent component={ClientComponent.SnackbarHost} />
      <Spacer height={32} />
      <h1>Example Web Page</h1>
      <Spacer height={24} />
      <span>{`user phone: ${userData().phone}`}</span>
      <span>{`user txn count: ${userData()["shop.totalPurchaseCount"]}`}</span>
      <Spacer height={24} />
      <Cta text="Get user" onClick={() => AppWebView.getUser()} />
      <Spacer height={24} />
      <Cta text="Go back" onClick={() => AppWebView.goBack()} />
      <Spacer height={24} />
      <Cta
        text="Trigger deeplink"
        onClick={() =>
          AppWebView.deeplinkTrigger(
            "/public/webview/?title=&url=https://universe.myhubble.money/gift"
          )
        }
      />
      <Spacer height={24} />
      <Cta
        text="Trigger Review Flow"
        onClick={() => {
          AppWebView.flowTrigger("request_review");
        }}
      />
      <Spacer height={24} />
      <Cta
        text="Fire profile dirty event"
        onClick={() => AppWebView.fireProfileDirtyEvent()}
      />
      <Spacer height={24} />
      <Cta
        text="Trigger location fetch"
        onClick={() => AppWebView.fetchLocation()}
      />
    </div>
  );

  function Cta(props: { onClick: () => void; text: string }) {
    return (
      <ButtonRegular class="w-full" isEnabled={true} onClick={props.onClick}>
        <span>{props.text}</span>
      </ButtonRegular>
    );
  }
}
